import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Log } from 'ng2-logger/browser';
import { isNullOrUndefined } from 'util';
import { AdditionalProductsModalComponent, AdditionalProductsModalData } from '../additional-products/additional-products-modal.component';
import { AdditionalProductVO } from '../additional-products/additional-products.service';
import { MLFModalData } from '../mlf-summary/mlf-dependant-modal.component';
import { MLFDependantVO } from '../mlf-summary/mlf-summary.service';
import { DropDownValuesVO } from '../select.vo';
import { LLCModalData } from './llc-dependant-modal.component';
import { LlcDependantComponent } from './llc-dependant.component';
import {LLCDependantVO} from './llc-summary.service';

const log = Log.create('LlcSummaryComponent');

@Component({
    selector: 'llc-summary',
    template: `
        <div class="mlf-summary">
            <div class="row">
                <div class="col s12">
                    <table class="dl-table">
                        <thead>
                        <tr>
                            <th class="text-left">Name</th>
                            <th class="text-right hide-on-small-and-down">Cover Amount</th>
                            <th class="text-right">Premium</th>
                            <th></th>
                        </tr>
                        </thead>
                        <ng-container *ngFor="let addtionalProduct of additionalProducts; let index = index">
                            <tr *ngIf="addtionalProduct.selected">
                                <td class="text-left">
                                    <span class="dl-badge">{{getBadge(addtionalProduct)}}</span>
                                    {{addtionalProduct.name}}
                                </td>
                                <td *ngIf="addtionalProduct.cover" class="text-right hide-on-small-and-down">{{addtionalProduct.cover | dl_currency:true:0}}</td>
                                <td *ngIf="!addtionalProduct.cover" class="text-right hide-on-small-and-down">N/A</td>
                                <td class="text-right">{{addtionalProduct.premium | dl_currency:true:2}}</td>
                                <td class="text-right">
                                    <span *ngIf="index === 0" class="hide-on-small-and-down spacer"></span>
                                    <span *ngIf="index !== 0" class="hide-on-med-and-up"><br></span>
                                    <i *ngIf="index !== 0" (click)="clickRemove(addtionalProduct.id, 'addon')" class="fa fa-times"></i>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngFor="let dependant of dependants; let index = index">
                            <tr>
                                <td class="text-left">
                                    <span class="dl-badge">{{getBadge(dependant)}}</span>
                                    {{dependant.first_name}} {{dependant.last_name}}
                                    <span class="hide-on-med-and-up"><br>{{dependant.benefit_amount | dl_currency:true:0}}</span>
                                </td>
                                <td class="text-right hide-on-small-and-down">{{dependant.benefit_amount | dl_currency:true:0}}</td>
                                <td class="text-right">{{dependant.premium_amount | dl_currency:true:2}}</td>
                                <td class="text-right">
                                    <i (click)="clickEdit(dependant, index === 0)" class="fa fa-pencil"></i>
                                    <span *ngIf="index === 0" class="hide-on-small-and-down spacer"></span>
                                    <span *ngIf="index !== 0" class="hide-on-med-and-up"><br></span>
                                    <i (click)="clickRemove(dependant.id, 'dependant')" class="fa fa-times"></i>
                                </td>
                            </tr>
                        </ng-container>
                        <tfoot>
                        <tr>
                            <td><b>Total</b></td>
                            <td class="hide-on-small-and-down"></td>
                            <td class="text-right"><b>{{getTotalPremium | dl_currency:true:2}}</b></td>
                            <td></td>
                        </tr>
                        <tfoot>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col s12">
                    <p class="text-center">Do you want to add additional features to your selection? Select one to learn more.</p>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('addon', 'LLC_AF')">Accelerated Funeral</button>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('addon', 'LLC_PPPH')">Premium Holiday</button>
                </div>
                <div class="col s8 offset-s2 m6 offset-m3 l4 offset-l4">
                    <button class="dl-full-width"
                            mat-raised-button
                            tabindex="1"
                            [attr.data-name]="'add'"
                            [attr.data-event]="'true'"
                            [attr.data-event-category]="eventCategory"
                            [attr.data-event-name]="'add-edit dependant'"
                            [attr.data-event-label]="'add-edit dependant'"
                            (click)="clickAdd('dependant')">Additional Lives</button>
                </div>
            </div>
        </div>
    `
})
export class LlcSummaryComponent {
    @Input()
    leadName: string;
    @Input()
    spId: string;
    @Input()
    benefitId: string;
    @Input()
    dependants: LLCDependantVO[]; // Dependents aka Spouse, Children etc which have been added as well as AF & PH
    @Input()
    dependantProducts: DropDownValuesVO[]; // LLC_F_S, LLC_F_C & LLC_F_E
    @Input()
    additionalProducts: AdditionalProductVO[]; // LLC_AF & LLC_PPPH
    @Input()
    eventCategory: string;
    @Output()
    add = new EventEmitter<{ product: AdditionalProductVO | LLCDependantVO, type: string }>();
    @Output()
    edit = new EventEmitter<any>();
    @Output()
    remove = new EventEmitter<{id: string, type: string}>();

    private dialogRef: MatDialogRef<AdditionalProductsModalComponent | LlcDependantComponent>

    constructor(private readonly dialog: MatDialog) {}

    get getTotalPremium(): number {
        try {
            let total: number = 0;

            if (!isNullOrUndefined(this.additionalProducts) && !isNullOrUndefined(this.dependants)) {
                this.additionalProducts.forEach((additionalProduct: AdditionalProductVO) => {
                    if (additionalProduct.selected) {
                        total += additionalProduct.premium;
                    }
                });

                this.dependants.forEach((dependant: LLCDependantVO) => {
                    total += dependant.premium_amount;
                });
            }

            return total;
        } catch (error) {
            log.error(error);
        }
    }

    public clickAdd(type: string, addonCode?: string): void {
        try {
            type === 'addon' 
                ? this.openModal(this.additionalProducts.find((additionalProduct: AdditionalProductVO) => additionalProduct.code === addonCode), false, false, type)
                : this.openModal(this.dependantProducts, false, false, type);
        } catch (error) {
            log.error(error);
        }
    }

    public clickRemove(id: string, type: string): void {
        try {
            this.remove.emit({ id, type });
        } catch (error) {
            log.error(error);
        }
    }

    clickEdit(dependant: LLCDependantVO, main: boolean): void {
        this.openModal(this.dependantProducts, true, false, 'LLC_AL', dependant);
    }

    private openModal(product: AdditionalProductVO | DropDownValuesVO[], editFlag: boolean, mainMemberFlag: boolean, type: string, dependant?: LLCDependantVO): void {
        try {
            let data: AdditionalProductsModalData | LLCModalData | any = {
                spId: this.spId,
                benefitId: this.benefitId,
                leadName: this.leadName,
                edit: editFlag,
                main: mainMemberFlag
            }

            data = type === 'addon' ? { ...data, product } : { ...data, dependant: !editFlag ? new LLCDependantVO() : dependant, products: product, dependants: this.dependants };

            this.dialogRef = this.dialog.open<AdditionalProductsModalComponent | LlcDependantComponent>(type === 'addon' ? AdditionalProductsModalComponent : LlcDependantComponent,
                {
                    disableClose: true,
                    panelClass: 'dl-modal',
                    maxWidth: '99vw',
                    position: { top: '20px' },
                    data
                }
            );

            this.dialogRef.beforeClose().subscribe((res) => {
                if (res === 'cancel') return;
                else type === 'addon' ? this.add.emit({ product: res.product, type }) : this.add.emit({ product: res.dependant, type });

                if (res.edit)
                    this.edit.emit({ main: res.main, dependant: res.dependant });
            });
        } catch (error) {
            log.error(error);
        }
    }

    public getBadge(additionalProduct: AdditionalProductVO | any): string {
        try {
            if (!isNullOrUndefined(additionalProduct) && !isNullOrUndefined(additionalProduct.name) && additionalProduct.name.length >= 1) return additionalProduct.name.substr(0, 1).toUpperCase();
            if (!isNullOrUndefined(additionalProduct.dependant)) return 'AL';

            return '?';
        } catch (error) {
            log.error(error);
        }
        
    }
}