import {Component, EventEmitter, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef, MatSliderChange} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {RsaId, rsaIdValidation, RsaIdValidationCallback} from '../rsa.id.validator';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {isNullOrUndefined} from 'util';
import {DLUtil} from '../dl.util';
import {CoverOptionsVO, MLFDependantVO, MLFSimplePricingRequestVO, MLFSummaryService, MLFUtil} from './mlf-summary.service';
import {Log} from 'ng2-logger/client';
import {DropDownValuesVO} from '../select.vo';
import {ErrorVO} from '../error/error.vo';

const log = Log.create('MLFDependantModalComponent');
@Component({
    selector: 'mlf-dependant-modal',
    templateUrl: './mlf-dependant-modal.component.html'
})
export class MLFDependantModalComponent implements RsaIdValidationCallback {

    form: FormGroup;
    coverOptions$: BehaviorSubject<CoverOptionsVO>;
    sliderPoints: any;
    changeEvents$: EventEmitter<ChangeEvent>;
    isValid = false;
    isBusy = false;
    benefit_amount: number;
    premium_amount: number;
    previouslyDuplicate = false;
    product_master_id: string;
    pricing: any;
    rsaId = new RsaId();
    productDescriptions: any = {
        MLF_S: 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65',
        MLF_C: 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65',
        MLF_E: '<b>Parents / In-Laws</b> are the biological parents of the policyholder, or the biological parents of the policy holders spouse. Age Min: 18, Max: 75'
    };

    constructor(private dialogRef: MatDialogRef<MLFDependantModalComponent>,
                private fb: FormBuilder,
                private summaryService: MLFSummaryService,
                @Inject(MAT_DIALOG_DATA) public data: MLFModalData) {
        this.setupForm(data.main);
        if (data.main) {
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
    }

    setupForm(main: boolean): void {
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            this.form = this.fb.group({x: ['']});
        } else {
            log.info('Dependant');
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe(() => this.checkDuplicate(true));
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(() => {
                this.checkDuplicate(true);
                this.rsaId.setId(this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(this.rsaId.getGender())) {
                    this.form.get('gender').setValue((this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }
            });
            this.form.get('product').valueChanges.subscribe(() => this.productSelected());
            this.form.get('gender').valueChanges.subscribe(() => this.genderChanged());
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(
                debounceTime(650),
                distinctUntilChanged((a, b) => {
                    return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
                })
            ).subscribe(
                // Update premiums for change events
                ($event) => this.fetchCoverOptions($event.rsaId, $event.product, $event.gender)
            );
        }
    }

    get title(): string {
        if (this.isDependant) {
            return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
        }
        return 'Edit Main Member';
    }

    get saveButtonText(): string {
        return this.data.edit ? 'Save' : 'Add';
    }

    get isDependant(): boolean {
        return !this.data.main;
    }

    cancel(): void {
        this.dialogRef.close('cancel');
    }

    selectionChange(id: string) {
    }

    save(): void {
        this.isBusy = true;
        const data = new MLFModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new MLFDependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    }

    get ageHint(): string {
        if (this.rsaId.isValidDOB()) {
            return 'Age: ' + this.rsaId.getAge() + ', Birthdate: ' + new Date(this.rsaId.getDOB()).toDateString();
        }
        return '';
    }

    get leadName(): string {
        return this.data.leadName;
    }

    onIdValidationChange(rsaId: RsaId): void {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            const product = this.form.get('product').value as string;
            const gender = this.form.get('gender').value as string;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
        }
    }

    private checkDuplicate(fetch: boolean): boolean {
        let isDuplicate = false;
        this.data.dependants.forEach(d => {
            if (this.data.dependant.id !== d.id) {
                if (d.first_name === this.form.get('first_name').value &&
                    d.last_name === this.form.get('last_name').value &&
                    d.id_or_dob === this.form.get('id_or_dob').value &&
                    d.gender === this.form.get('gender').value) {
                    log.info('duplicate dependant', d);
                    isDuplicate = true;
                    this.isValid = false;
                    const coverOptions = new CoverOptionsVO('invalid');
                    coverOptions.error = 'Duplicate';
                    this.coverOptions$.next(coverOptions);
                }
            }
        });
        if (!isDuplicate && fetch && this.previouslyDuplicate) {
            const coverOptions = new CoverOptionsVO('invalid');
            this.coverOptions$.next(coverOptions);
            const rsaId = new RsaId();
            rsaId.setId(this.form.get('id_or_dob').value);
            const product = this.form.get('product').value;
            const gender = this.form.get('gender').value;
            this.fetchCoverOptions(rsaId, product, gender);
        }
        this.previouslyDuplicate = isDuplicate;
        return isDuplicate;
    }

    private invalidateSlider(message: string) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    }

    private fetchCoverOptions(rsaId: RsaId, product: string, inGender: string = null) {
        let age = 0;
        let gender = null;
        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
            if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
                this.invalidateSlider('dob is invalid or no product');
                return;
            }
            age = rsaId.getAge();
            if (isNullOrUndefined(rsaId.getGender())) {
                gender = inGender;
            } else {
                gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
            }
            if (isNullOrUndefined(gender)) {
                this.invalidateSlider('gender is invalid');
                return;
            }
            if (this.checkDuplicate(false)) {
                this.invalidateSlider('');
                return;
            }
        }
        log.info('loading cover options for ' + product + ', ' + age);
        this.isValid = false;
        this.form.disable({onlySelf: true, emitEvent: false});
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        const req: MLFSimplePricingRequestVO = {
            spId: this.data.spId,
            benefitId: this.data.benefitId,
            product: product,
            age: age,
            gender: gender
        };
        this.summaryService.getPricing(req).subscribe((res) => {
            this.updateCoverOptions(rsaId, product, res);
            this.form.enable({onlySelf: true, emitEvent: false});
        }, (error: any) => {
            log.error('Error getting Dependant Info', error);
            this.form.enable({onlySelf: true, emitEvent: false});
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            this.coverOptions$.next(coverOptions);
        });
    }

    productSelected(): void {
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        const gender = this.form.get('gender').value;
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }

    genderChanged(): void {
        const rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        let gender = this.form.get('gender').value;
        if (!isNullOrUndefined(rsaId.getGender())) {
            gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
        }
        if (gender !== this.form.get('gender').value) {
            // Flip it back to what ID number says...
            this.form.get('gender').setValue(gender);
            return;
        }
        const product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    }

    get productHint(): string {
        const product = this.form.get('product').value;
        if (!isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product])) {
            return this.productDescriptions[product];
        }
        return null;
    }

    updateCoverOptions(rsaId: RsaId, product: string, res: CoverOptionsVO) {
        log.info('results from pricing', res);
        if (isNullOrUndefined(res)) {
            return;
        }
        if (res.hasOwnProperty('error')) {
            const coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        } else {
            res.status = 'valid';
            if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
                res.cover = this.benefit_amount;
            } else {
                res.cover = res.max;
            }
            this.sliderPoints = res.options;
            this.benefit_amount = res.cover;
            this.premium_amount = this.findPremiumForCover(res.cover);
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = true;
            this.coverOptions$.next(res);
        }
    }

    formatDisplay(value: number | null): string {
        return DLUtil.compactFormat(value, false);
    }

    updateCover(val: MatSliderChange): void {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.form.markAsDirty();
    }

    private findPremiumForCover(cover): number {
        let premium = 0;
        this.sliderPoints.forEach((opt) => {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    }

    get disableSave(): boolean {
        return !this.isValid || this.form.pristine || this.form.invalid;
    }

    getDisabledText(item: DropDownValuesVO) {
        if (item.disabled && !isNullOrUndefined(MLFUtil.validation[item.id])) {
            return ' (Max: ' + MLFUtil.validation[item.id] + ')';
        }
        return '';
    }

}

export class MLFModalData {
    spId: string;
    benefitId: string;
    leadName: string;
    edit: boolean;
    main: boolean;
    dependant: MLFDependantVO;
    products: DropDownValuesVO[];
    dependants: MLFDependantVO[];
}

class ChangeEvent {
    rsaId: RsaId;
    product: string;
    gender: string;

    constructor(rsaId: RsaId, product: string, gender: string) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
}
