import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material';
import {MLFDependantModalComponent, MLFModalData} from './mlf-dependant-modal.component';
import {DropDownValuesVO, SelectItem} from '../select.vo';
import {MLFDependantVO, MLFUtil} from './mlf-summary.service';
import {isNullOrUndefined} from "util";

@Component({
    templateUrl: 'mlf-base-summary.component.html',
    selector: 'mlf-base-summary'
})
export class MLFBaseSummaryComponent {

    @Input()
    leadName: string;
    @Input()
    spId: string;
    @Input()
    benefitId: string;
    @Input()
    dependants: MLFDependantVO[];
    @Input()
    products: DropDownValuesVO[];
    @Input()
    eventCategory: string;
    @Output()
    add = new EventEmitter<MLFDependantVO>();
    @Output()
    edit = new EventEmitter<any>();
    @Output()
    remove = new EventEmitter<MLFDependantVO>();
    private dialogRef: MatDialogRef<MLFDependantModalComponent>;

    constructor(
        private dialog: MatDialog) {
    }

    public static removeDependant(dependants: MLFDependantVO[], idToRemove: string): MLFDependantVO[] {
        const list: MLFDependantVO[] = [];
        dependants.forEach((d) => {
            if (d.id !== idToRemove) {
                list.push(d);
            }
        });
        return list;
    }

    clickAdd(): void {
        this.openModal(new MLFDependantVO(), false, false);
    }

    clickEdit(dependant: MLFDependantVO, main: boolean): void {
        this.openModal(dependant, true, main);
    }

    private openModal(dependant: MLFDependantVO, edit: boolean, main: boolean) {
        const data: MLFModalData = {
            spId: this.spId,
            benefitId: this.benefitId,
            leadName: this.leadName,
            edit: edit,
            main: main,
            dependant: dependant,
            products: this.products,
            dependants: this.dependants
        };
        this.dialogRef = this.dialog.open(MLFDependantModalComponent,
            {
                disableClose: true,
                panelClass: 'dl-modal',
                maxWidth: '99vw',
                position: {top: '20px'},
                data: data
            }
        );
        this.dialogRef.beforeClose().subscribe((res: any) => {
            if (res instanceof MLFModalData) {
                if (res.edit) {
                    this.edit.emit({main: res.main, dependant: res.dependant});
                } else {
                    this.add.emit(res.dependant);
                }
            }
        });
    }

    get totalPremium(): number {
        let total = 0;
        if (!isNullOrUndefined(this.dependants)) {
            this.dependants.forEach((d) => {
                total += d.premium_amount;
            });
        }
        return total;
    }

    clickRemove(dependant: MLFDependantVO) {
        dependant.deleting = true;
        this.remove.emit(dependant);
    }

    getBadge(d: MLFDependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel.substr(0, 1);
        }
        return '?';
    }

    getHint(d: MLFDependantVO): string {
        if (!isNullOrUndefined(d) && !isNullOrUndefined(d.rel) && d.rel.length >= 1) {
            return d.rel;
        }
        return 'Unknown';
    }

    get canAdd(): boolean {
        if (isNullOrUndefined(this.dependants)) {
            return false;
        }
        const counts = {
            MLF_S: 0,
            MLF_C: 0,
            MLF_E: 0,
        };
        this.dependants.forEach(d => {
            if (d.product === 'MLF_S') {
                counts.MLF_S++;
            } else if (d.product === 'MLF_C') {
                counts.MLF_C++;
            } else if (d.product === 'MLF_E') {
                counts.MLF_E++;
            }
        });
        this.disableDropDown('MLF_S', (counts.MLF_S === MLFUtil.validation.MLF_S));
        this.disableDropDown('MLF_C', (counts.MLF_C === MLFUtil.validation.MLF_C));
        this.disableDropDown('MLF_E', (counts.MLF_E === MLFUtil.validation.MLF_E));
        return !(counts.MLF_S === MLFUtil.validation.MLF_S &&
            counts.MLF_C === MLFUtil.validation.MLF_C &&
            counts.MLF_E === MLFUtil.validation.MLF_E);
    }

    private disableDropDown(key: string, disabled: boolean) {
        this.products.forEach(p => {
            if (p.id === key) {
                p.disabled = disabled;
            }
        });
    }

}
