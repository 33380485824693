import { EventEmitter } from '@angular/core';
import { MatDialogRef, MatSliderChange } from '@angular/material';
import { FormBuilder, Validators } from '@angular/forms';
import { RsaId, rsaIdValidation } from '../rsa.id.validator';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { DLUtil } from '../dl.util';
import { CoverOptionsVO, MLFDependantVO, MLFSummaryService, MLFUtil } from './mlf-summary.service';
import { Log } from 'ng2-logger/client';
import { ErrorVO } from '../error/error.vo';
var log = Log.create('MLFDependantModalComponent');
var MLFDependantModalComponent = /** @class */ (function () {
    function MLFDependantModalComponent(dialogRef, fb, summaryService, data) {
        this.dialogRef = dialogRef;
        this.fb = fb;
        this.summaryService = summaryService;
        this.data = data;
        this.isValid = false;
        this.isBusy = false;
        this.previouslyDuplicate = false;
        this.rsaId = new RsaId();
        this.productDescriptions = {
            MLF_S: 'A <b>Spouse</b> is a person who is married to the policyholder in terms of law, common law, customary or as a partner (lived together for more than 12-months). Age Min: 18, Max: 65',
            MLF_C: 'A <b>Child</b> is a child born to the policyholder or spouse, or partner as well as legally adopted and guardian children. Age Max: 65',
            MLF_E: '<b>Parents / In-Laws</b> are the biological parents of the policyholder, or the biological parents of the policy holders spouse. Age Min: 18, Max: 75'
        };
        this.setupForm(data.main);
        if (data.main) {
            this.fetchCoverOptions(null, data.dependant.product);
        }
        if (!isNullOrUndefined(data.dependant.benefit_amount)) {
            this.benefit_amount = data.dependant.benefit_amount;
        }
        if (!isNullOrUndefined(data.dependant.premium_amount)) {
            this.premium_amount = data.dependant.premium_amount;
        }
    }
    MLFDependantModalComponent.prototype.setupForm = function (main) {
        var _this = this;
        this.coverOptions$ = new BehaviorSubject(new CoverOptionsVO('invalid'));
        this.changeEvents$ = new EventEmitter();
        if (main) {
            log.info('Main Member');
            this.form = this.fb.group({ x: [''] });
        }
        else {
            log.info('Dependant');
            this.form = this.fb.group({
                product: [this.data.dependant.product, [Validators.required]],
                first_name: [this.data.dependant.first_name, [Validators.required]],
                last_name: [this.data.dependant.last_name, [Validators.required]],
                gender: [this.data.dependant.gender, [Validators.required]],
                id_or_dob: [this.data.dependant.id_or_dob, [Validators.required, Validators.pattern('[0-9]*'), rsaIdValidation(true, this)]]
            });
            this.form.get('first_name').valueChanges.pipe(debounceTime(450)).subscribe(function () { return _this.checkDuplicate(true); });
            this.form.get('last_name').valueChanges.pipe(debounceTime(450)).subscribe(function () { return _this.checkDuplicate(true); });
            this.form.get('id_or_dob').valueChanges.pipe(debounceTime(450)).subscribe(function () {
                _this.checkDuplicate(true);
                _this.rsaId.setId(_this.form.get('id_or_dob').value);
                if (!isNullOrUndefined(_this.rsaId.getGender())) {
                    _this.form.get('gender').setValue((_this.rsaId.getGender() === 'Male' ? 'M' : 'F'));
                }
            });
            this.form.get('product').valueChanges.subscribe(function () { return _this.productSelected(); });
            this.form.get('gender').valueChanges.subscribe(function () { return _this.genderChanged(); });
            // Initialize an Observable for consolidated, debounced change events
            this.changeEvents$.pipe(debounceTime(650), distinctUntilChanged(function (a, b) {
                return a.rsaId.getId() === b.rsaId.getId() && a.product === b.product && a.gender === b.gender;
            })).subscribe(
            // Update premiums for change events
            function ($event) { return _this.fetchCoverOptions($event.rsaId, $event.product, $event.gender); });
        }
    };
    Object.defineProperty(MLFDependantModalComponent.prototype, "title", {
        get: function () {
            if (this.isDependant) {
                return (this.data.edit ? 'Edit' : 'Add') + ' additional life';
            }
            return 'Edit Main Member';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFDependantModalComponent.prototype, "saveButtonText", {
        get: function () {
            return this.data.edit ? 'Save' : 'Add';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFDependantModalComponent.prototype, "isDependant", {
        get: function () {
            return !this.data.main;
        },
        enumerable: true,
        configurable: true
    });
    MLFDependantModalComponent.prototype.cancel = function () {
        this.dialogRef.close('cancel');
    };
    MLFDependantModalComponent.prototype.selectionChange = function (id) {
    };
    MLFDependantModalComponent.prototype.save = function () {
        this.isBusy = true;
        var data = new MLFModalData();
        DLUtil.copyFields(this.data, data);
        data.dependant = new MLFDependantVO();
        DLUtil.copyFields(this.data.dependant, data.dependant);
        DLUtil.copyFields(this.form.value, data.dependant);
        data.dependant.benefit_amount = this.benefit_amount;
        data.dependant.premium_amount = this.premium_amount;
        data.dependant.product_master_id = this.product_master_id;
        data.dependant.pricing = this.pricing;
        data.dependant.age = this.rsaId.getAge();
        this.dialogRef.close(data);
    };
    Object.defineProperty(MLFDependantModalComponent.prototype, "ageHint", {
        get: function () {
            if (this.rsaId.isValidDOB()) {
                return 'Age: ' + this.rsaId.getAge() + ', Birthdate: ' + new Date(this.rsaId.getDOB()).toDateString();
            }
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MLFDependantModalComponent.prototype, "leadName", {
        get: function () {
            return this.data.leadName;
        },
        enumerable: true,
        configurable: true
    });
    MLFDependantModalComponent.prototype.onIdValidationChange = function (rsaId) {
        if (!isNullOrUndefined(this.form) &&
            !isNullOrUndefined(this.form.get('product')) &&
            !isNullOrUndefined(this.form.get('product').value) &&
            !isNullOrUndefined(this.form.get('gender').value)) {
            var product = this.form.get('product').value;
            var gender = this.form.get('gender').value;
            this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
        }
    };
    MLFDependantModalComponent.prototype.checkDuplicate = function (fetch) {
        var _this = this;
        var isDuplicate = false;
        this.data.dependants.forEach(function (d) {
            if (_this.data.dependant.id !== d.id) {
                if (d.first_name === _this.form.get('first_name').value &&
                    d.last_name === _this.form.get('last_name').value &&
                    d.id_or_dob === _this.form.get('id_or_dob').value &&
                    d.gender === _this.form.get('gender').value) {
                    log.info('duplicate dependant', d);
                    isDuplicate = true;
                    _this.isValid = false;
                    var coverOptions = new CoverOptionsVO('invalid');
                    coverOptions.error = 'Duplicate';
                    _this.coverOptions$.next(coverOptions);
                }
            }
        });
        if (!isDuplicate && fetch && this.previouslyDuplicate) {
            var coverOptions = new CoverOptionsVO('invalid');
            this.coverOptions$.next(coverOptions);
            var rsaId = new RsaId();
            rsaId.setId(this.form.get('id_or_dob').value);
            var product = this.form.get('product').value;
            var gender = this.form.get('gender').value;
            this.fetchCoverOptions(rsaId, product, gender);
        }
        this.previouslyDuplicate = isDuplicate;
        return isDuplicate;
    };
    MLFDependantModalComponent.prototype.invalidateSlider = function (message) {
        log.info(message);
        this.coverOptions$.next(new CoverOptionsVO('invalid'));
    };
    MLFDependantModalComponent.prototype.fetchCoverOptions = function (rsaId, product, inGender) {
        var _this = this;
        if (inGender === void 0) { inGender = null; }
        var age = 0;
        var gender = null;
        if (!this.data.main) {
            if (isNullOrUndefined(product) || product.length === 0) {
                this.invalidateSlider('no product selected');
                return;
            }
            if (isNullOrUndefined(rsaId.getId()) || (rsaId.getId().length > 8 && !rsaId.isValid())) {
                this.invalidateSlider('id is invalid');
                return;
            }
            if (!rsaId.isValidDOB() || isNullOrUndefined(product)) {
                this.invalidateSlider('dob is invalid or no product');
                return;
            }
            age = rsaId.getAge();
            if (isNullOrUndefined(rsaId.getGender())) {
                gender = inGender;
            }
            else {
                gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
            }
            if (isNullOrUndefined(gender)) {
                this.invalidateSlider('gender is invalid');
                return;
            }
            if (this.checkDuplicate(false)) {
                this.invalidateSlider('');
                return;
            }
        }
        log.info('loading cover options for ' + product + ', ' + age);
        this.isValid = false;
        this.form.disable({ onlySelf: true, emitEvent: false });
        this.coverOptions$.next(new CoverOptionsVO('loading'));
        var req = {
            spId: this.data.spId,
            benefitId: this.data.benefitId,
            product: product,
            age: age,
            gender: gender
        };
        this.summaryService.getPricing(req).subscribe(function (res) {
            _this.updateCoverOptions(rsaId, product, res);
            _this.form.enable({ onlySelf: true, emitEvent: false });
        }, function (error) {
            log.error('Error getting Dependant Info', error);
            _this.form.enable({ onlySelf: true, emitEvent: false });
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = ErrorVO.toErrorVO(error).message;
            _this.coverOptions$.next(coverOptions);
        });
    };
    MLFDependantModalComponent.prototype.productSelected = function () {
        var rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        var gender = this.form.get('gender').value;
        var product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    };
    MLFDependantModalComponent.prototype.genderChanged = function () {
        var rsaId = new RsaId();
        rsaId.setId(this.form.get('id_or_dob').value);
        var gender = this.form.get('gender').value;
        if (!isNullOrUndefined(rsaId.getGender())) {
            gender = rsaId.getGender() === 'Male' ? 'M' : 'F';
        }
        if (gender !== this.form.get('gender').value) {
            // Flip it back to what ID number says...
            this.form.get('gender').setValue(gender);
            return;
        }
        var product = this.form.get('product').value;
        this.changeEvents$.emit(new ChangeEvent(rsaId, product, gender));
    };
    Object.defineProperty(MLFDependantModalComponent.prototype, "productHint", {
        get: function () {
            var product = this.form.get('product').value;
            if (!isNullOrUndefined(product) && !isNullOrUndefined(this.productDescriptions[product])) {
                return this.productDescriptions[product];
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    MLFDependantModalComponent.prototype.updateCoverOptions = function (rsaId, product, res) {
        log.info('results from pricing', res);
        if (isNullOrUndefined(res)) {
            return;
        }
        if (res.hasOwnProperty('error')) {
            var coverOptions = new CoverOptionsVO('invalid');
            coverOptions.error = res.error;
            this.isValid = false;
            this.coverOptions$.next(coverOptions);
        }
        else {
            res.status = 'valid';
            if (!isNullOrUndefined(this.benefit_amount) && this.benefit_amount < res.max) {
                res.cover = this.benefit_amount;
            }
            else {
                res.cover = res.max;
            }
            this.sliderPoints = res.options;
            this.benefit_amount = res.cover;
            this.premium_amount = this.findPremiumForCover(res.cover);
            this.product_master_id = res.product_master_id;
            this.pricing = res.pricing;
            this.isValid = true;
            this.coverOptions$.next(res);
        }
    };
    MLFDependantModalComponent.prototype.formatDisplay = function (value) {
        return DLUtil.compactFormat(value, false);
    };
    MLFDependantModalComponent.prototype.updateCover = function (val) {
        this.benefit_amount = val.value;
        this.premium_amount = this.findPremiumForCover(val.value);
        this.form.markAsDirty();
    };
    MLFDependantModalComponent.prototype.findPremiumForCover = function (cover) {
        var premium = 0;
        this.sliderPoints.forEach(function (opt) {
            if (cover === opt.cover) {
                premium = opt.premium;
            }
        });
        return premium;
    };
    Object.defineProperty(MLFDependantModalComponent.prototype, "disableSave", {
        get: function () {
            return !this.isValid || this.form.pristine || this.form.invalid;
        },
        enumerable: true,
        configurable: true
    });
    MLFDependantModalComponent.prototype.getDisabledText = function (item) {
        if (item.disabled && !isNullOrUndefined(MLFUtil.validation[item.id])) {
            return ' (Max: ' + MLFUtil.validation[item.id] + ')';
        }
        return '';
    };
    return MLFDependantModalComponent;
}());
export { MLFDependantModalComponent };
var MLFModalData = /** @class */ (function () {
    function MLFModalData() {
    }
    return MLFModalData;
}());
export { MLFModalData };
var ChangeEvent = /** @class */ (function () {
    function ChangeEvent(rsaId, product, gender) {
        this.rsaId = rsaId;
        this.product = product;
        this.gender = gender;
    }
    return ChangeEvent;
}());
