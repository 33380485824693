var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [0, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnDestroy } from '@angular/core';
import { PageComponentVO, PageInfoVO } from '../page.data.vo';
import { isNullOrUndefined } from 'util';
import { TextComponent } from '../component/text.component';
import { RsaIdComponent } from '../component/rsa.id.component';
import { SelectComponent } from '../component/select.component';
import { ProductComponent } from '../component/product.component';
import { InfoComponent } from '../component/info.component';
import { TelComponent } from '../component/tel.component';
import { EmailComponent } from '../component/email.component';
import { BankingComponent } from '../component/banking.component';
import { BeneficiariesComponent } from '../component/beneficiaries.component';
import { FormBuilder, FormControl } from '@angular/forms';
import { LinkButtonComponent } from '../component/link.button.component';
import { DspService } from '../dsp.service';
import { ResumeButtonComponent } from '../component/resume.button.component';
import { Log } from 'ng2-logger/client';
import { NumberComponent } from '../component/number.component';
import { DatePickerComponent } from '../component/date-picker.component';
import { ParentEventService } from '../component/component';
import { AltContinueComponent } from '../component/alt.continue.component';
import { RadioGroupComponent } from '../component/radio.group.component';
import { CheckboxGroupComponent } from '../component/checkbox.group.component';
import { CheckboxComponent } from '../component/checkbox.component';
import { BmiComponent } from '../component/bmi.component';
import { BeneficiariesUKComponent } from '../component/beneficiaries-uk.component';
import { DotOrgComponent } from '../component/dot-org.component';
import { InfoCardComponent } from '../component/info-card.component';
import { StripeElementComponent } from '../component/stripe.element.component';
import { WindowRef } from '../../base/window.ref';
import { DateComponent } from '../component/date.component';
import { UkPostcodeComponent } from '../component/uk.postcode.component';
import { CustomiseCoverComponent } from '../component/customise-cover.component';
import { OfferInfoCardComponent } from '../component/offer-info-card.component';
import { AjaxButtonComponent } from '../component/ajax.button.component';
import { MailDraftDocInfoComponent } from '../component/mail.draft.doc.info.component';
import { MLFMainComponent } from '../component/mlf-main.component';
import { MLFSummaryComponent } from '../component/mlf-summary.component';
import { RsaIdDobComponent } from '../component/rsa.id.dob.component';
import { HiddenComponent } from '../component/hidden.component';
import { LLCMainComponent } from '../component/llc-main.component';
import { MatDialog } from '@angular/material';
import { NotesDialogComponent } from '../../sp/confirmation/confirmation.component';
import { RadioGroupHorizontalComponent } from '../component/radio.group.horizontal.component';
import { LlcAdditionalProductsComponent } from '../component/llc-additional-products.component';
import { QqComponent } from 'src/app/qq/qq.component';
import { OkDisclosureComponent } from '../component/ok-disclosure.component';
import { PantSizeComponent } from '../component/pantsize.component';
var log = Log.create('PageComponent');
/**
 * Created by corneliusbotha on 2017/07/18.
 */
var PageComponent = /** @class */ (function () {
    function PageComponent(fb, dspService, parentEventService, windowRef, dialog) {
        var _this = this;
        this.fb = fb;
        this.dspService = dspService;
        this.parentEventService = parentEventService;
        this.windowRef = windowRef;
        this.dialog = dialog;
        this.addressNotes = '';
        this.caseNotes = '';
        this.rows = [];
        this.pageValid = false;
        this.busy = false;
        this.disable = false;
        this.showCaseCreationOptions = false;
        this.createCaseEnabled = false;
        this.casesCreated = false;
        this.caseOptionsDisabled = false;
        this.formId = '';
        this.continueEvent = new EventEmitter();
        this.pageForm = this.fb.group({ placeholder: '' });
        this.busySubscription = this.parentEventService.getPageBusyObservable().subscribe(function (event) {
            _this.disable = event;
            if (_this.busy && !event) {
                _this.busy = false;
            }
        });
    }
    Object.defineProperty(PageComponent.prototype, "pageInfo", {
        get: function () {
            return this._pageInfo;
        },
        set: function (data) {
            log.info('set PageInfo');
            log.info('DATA', data);
            this.formId = data.flowId.toUpperCase() + '-' + data.pageName.toUpperCase();
            this._pageInfo = data;
            this.windowRef.nativeWindow.spid = data.spId;
            this.pageValid = false;
            this.busy = false;
            this.disable = false;
            this.parentEventService.publishPageBusyStatus(false);
            this.rows = [];
            this.clearFormControls();
            this.pageForm.addControl('createPostDocCase', new FormControl(false));
            this.pageForm.addControl('createPostSaleCase', new FormControl(false));
            if (!isNullOrUndefined(this._pageInfo.components)) {
                var hasContinue = true;
                if (isNullOrUndefined(this._pageInfo.continueText) || this._pageInfo.continueText.length === 0) {
                    hasContinue = false;
                    if (this._pageInfo.mode === 'embedded') {
                        this.showCaseCreationOptions = true;
                    }
                }
                var row = new Row();
                if (!isNullOrUndefined(this._pageInfo.components)) {
                    for (var i = 0; i < this._pageInfo.components.length; i++) {
                        var component = this._pageInfo.components[i];
                        log.info('Add Component id: ' + component.id + ', type: ' + component.type);
                        component.isDynamic = true;
                        if (row.items.length === 0) {
                            this.rows.push(row);
                        }
                        else if (row.items.length === 2) {
                            row = new Row();
                            this.rows.push(row);
                        }
                        row.items.push(component);
                        if (component.shareRow) {
                            component.colClass = 'col s12 m6';
                        }
                        else {
                            component.colClass = 'col s12';
                            row = new Row();
                        }
                        switch (component.type) {
                            case 'text':
                                component.component = TextComponent;
                                break;
                            case 'number':
                                component.component = NumberComponent;
                                break;
                            case 'rsaid':
                                component.component = RsaIdComponent;
                                break;
                            case 'rsaiddob':
                                component.component = RsaIdDobComponent;
                                break;
                            case 'select':
                                component.component = SelectComponent;
                                break;
                            case 'product':
                                component.component = ProductComponent;
                                var products = component.extraInput;
                                if (products.length > 0) {
                                    component.isValid = true;
                                }
                                break;
                            case 'info':
                                component.component = InfoComponent;
                                component.isValid = true;
                                break;
                            case 'ok-disclosure':
                                component.component = OkDisclosureComponent;
                                component.isValid = true;
                                break;
                            case 'info-card':
                                component.component = InfoCardComponent;
                                component.isValid = true;
                                break;
                            case 'offer-info-card':
                                component.component = OfferInfoCardComponent;
                                component.isValid = true;
                                break;
                            case 'tel':
                                component.component = TelComponent;
                                break;
                            case 'email':
                                component.component = EmailComponent;
                                break;
                            case 'banking':
                                component.component = BankingComponent;
                                component.isValid = true;
                                break;
                            case 'beneficiary':
                                component.component = BeneficiariesComponent;
                                break;
                            case 'beneficiary-uk':
                                component.component = BeneficiariesUKComponent;
                                break;
                            case 'date-picker':
                                component.component = DatePickerComponent;
                                break;
                            case 'date':
                                component.component = DateComponent;
                                break;
                            case 'link-button':
                                component.component = LinkButtonComponent;
                                component.isValid = true;
                                break;
                            case 'resume-button':
                                component.component = ResumeButtonComponent;
                                component.isValid = true;
                                break;
                            case 'alt-continue':
                                component.component = AltContinueComponent;
                                component.isValid = true;
                                hasContinue = true;
                                break;
                            case 'radio-group':
                                component.component = RadioGroupComponent;
                                break;
                            case 'radio-group-horizontal':
                                component.component = RadioGroupHorizontalComponent;
                                break;
                            case 'check-group':
                                component.component = CheckboxGroupComponent;
                                break;
                            case 'check-box':
                                component.component = CheckboxComponent;
                                break;
                            case 'bmi':
                                component.component = BmiComponent;
                                component.isValid = true;
                                break;
                            case 'dot-org':
                                component.component = DotOrgComponent;
                                component.isValid = true;
                                break;
                            case 'stripe-element':
                                component.component = StripeElementComponent;
                                break;
                            case 'uk-post-code':
                                component.component = UkPostcodeComponent;
                                break;
                            case 'customise-cover':
                                component.component = CustomiseCoverComponent;
                                break;
                            case 'ajax-btn':
                                component.component = AjaxButtonComponent;
                                component.isValid = true;
                                if (!component.shareRow) {
                                    component.colClass = 'col s12 m6 offset-m6';
                                }
                                break;
                            case 'mail-draft-doc':
                                component.component = MailDraftDocInfoComponent;
                                component.isValid = true;
                                break;
                            case 'mlf-main':
                                component.component = MLFMainComponent;
                                component.isValid = true;
                                break;
                            case 'mlf-summary':
                                component.component = MLFSummaryComponent;
                                component.isValid = true;
                                break;
                            case 'hidden':
                                component.component = HiddenComponent;
                                component.isValid = true;
                                break;
                            case 'llc-main':
                                component.component = LLCMainComponent;
                                component.isValid = true;
                                break;
                            case 'llc-additional-products':
                                component.component = LlcAdditionalProductsComponent;
                                component.isValid = true;
                                break;
                            case 'quick-quote': {
                                component.component = QqComponent;
                                // component.isValid = false;
                                component.name = 'qq-mlf';
                                component.type = 'mlf';
                                break;
                            }
                            case 'pant-size':
                                component.component = PantSizeComponent;
                                break;
                            default:
                                log.warn('No component for ' + component.id + ' of type ' + component.type);
                                continue;
                        }
                        component.pageForm = this.pageForm;
                        component.counter = i + 1;
                        // increase counter by one to fix tabbing
                        component.counter++;
                    }
                    if (this._pageInfo.continueShareRow) {
                        var continueButton = new PageComponentVO();
                        continueButton.isDynamic = false;
                        continueButton.shareRow = true;
                        continueButton.colClass = 'col m6 s12';
                        var lastComponent = this._pageInfo.components[this._pageInfo.components.length - 1];
                        if (lastComponent.type === 'alt-continue' && lastComponent.shareRow) {
                            lastComponent.colClass = 'col s6';
                            continueButton.colClass = 'col s6';
                        }
                        // add to last row.
                        this.rows[this.rows.length - 1].items.push(continueButton);
                    }
                }
                if (!hasContinue) {
                    log.info('No Continue, set displayWarning off');
                    this.windowRef.nativeWindow.displayWarning = false;
                }
            }
            this.checkPageValidity();
            this.onChanges();
        },
        enumerable: true,
        configurable: true
    });
    PageComponent.prototype.ngOnDestroy = function () {
        this.busySubscription.unsubscribe();
    };
    PageComponent.prototype.doDynamicCompLogic = function (comp) {
        this.checkPageValidity();
        switch (comp.component) {
            case SelectComponent: {
                if (this._pageInfo.flowId === 'MLF' || 'LLCF') {
                    if (comp.id === 'beneficiaryRelation') {
                        this.changeFormEstateFields(comp.value);
                        this.checkPageValidity();
                    }
                }
            }
        }
    };
    PageComponent.prototype.checkPageValidity = function () {
        // if (this._pageInfo.pageName === 'beneficiaries') {
        //     for (const ctrl of this._pageInfo.components) {
        //         if(ctrl.id === 'beneficiaryRelation' && ctrl.value !== null) {
        //             this.changeFormEstateFields(ctrl.value);
        //         }
        //     }
        // }
        var valid = true;
        if (!isNullOrUndefined(this._pageInfo.components)) {
            for (var i = 0; i < this._pageInfo.components.length; i++) {
                // log.info('Component [' + this._pageInfo.components[i].name + '], isValid [' + this._pageInfo.components[i].isValid + ']');
                if (!this._pageInfo.components[i].isValid) {
                    valid = false;
                    break;
                }
            }
            this.pageValid = valid;
        }
        else {
            this.pageValid = false;
        }
        // publish validated status to all listening children
        this.parentEventService.publishPageValidatedStatus(this.pageValid);
    };
    PageComponent.prototype.changeFormEstateFields = function (value) {
        this.enableOrDisableComponentsForEstate('beneficiaryIdDob', value !== 'estate');
        this.enableOrDisableComponentsForEstate('beneficiaryPhone', value !== 'estate');
        this.enableOrDisableComponentsForEstate('beneficiaryEmail', value !== 'estate');
    };
    PageComponent.prototype.enableOrDisableComponentsForEstate = function (id, enable) {
        for (var _i = 0, _a = this._pageInfo.components; _i < _a.length; _i++) {
            var ctrl = _a[_i];
            if (ctrl.id === id) {
                if (enable) {
                    ctrl.pageForm.get(id).enable();
                }
                else {
                    ctrl.pageForm.get(id).disable();
                    ctrl.isValid = true;
                }
                break;
            }
        }
    };
    PageComponent.prototype.onPostDocsChanged = function () {
        var _this = this;
        log.info('post doc clicked');
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('post doc clicked and true');
            var dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'Please specify the address', notes: '' }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                console.log('The dialog was closed: ' + result);
                _this.addressNotes = 'Address: \n' + result;
            });
        }
    };
    PageComponent.prototype.onPostSaleCaseChanged = function () {
        var _this = this;
        log.info('post sale clicked');
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('post sale clicked and true');
            var dialogRef = this.dialog.open(NotesDialogComponent, {
                disableClose: true,
                position: { top: '20px' },
                maxWidth: '99vw',
                panelClass: 'dl-modal',
                data: { title: 'What does the client need assistance with?', notes: '' }
            });
            dialogRef.afterClosed().subscribe(function (result) {
                console.log('The dialog was closed: ' + result);
                _this.caseNotes = result;
            });
        }
    };
    PageComponent.prototype.onChanges = function () {
        var _this = this;
        this.pageForm.get('createPostDocCase').valueChanges.subscribe(function (val) {
            if (_this.getBooleanValueFromForm('createPostDocCase') || _this.getBooleanValueFromForm('createPostSaleCase')) {
                _this.createCaseEnabled = true;
            }
            else {
                _this.createCaseEnabled = false;
            }
        });
        this.pageForm.get('createPostSaleCase').valueChanges.subscribe(function (val) {
            if (_this.getBooleanValueFromForm('createPostDocCase') || _this.getBooleanValueFromForm('createPostSaleCase')) {
                _this.createCaseEnabled = true;
            }
            else {
                _this.createCaseEnabled = false;
            }
        });
    };
    PageComponent.prototype.itemSetupComplete = function () {
        var _this = this;
        var all_loaded = true;
        if (!isNullOrUndefined(this._pageInfo.components)) {
            for (var _i = 0, _a = this.pageInfo.components; _i < _a.length; _i++) {
                var item = _a[_i];
                if (!item.loaded) {
                    all_loaded = false;
                    break;
                }
            }
        }
        if (all_loaded) {
            setTimeout(function () {
                _this.resetForm();
                if (!isNullOrUndefined(_this.dspService.currentData) && !isNullOrUndefined(_this.dspService.currentData.components)) {
                    for (var _i = 0, _a = _this.dspService.currentData.components; _i < _a.length; _i++) {
                        var comp = _a[_i];
                        comp.dynamicControlRef.setInitialValue();
                    }
                }
                // publish validated status to all listening children
                _this.parentEventService.publishPageValidatedStatus(_this.pageValid);
            });
        }
    };
    /**
     * this function is fired when a child component that needs extra validation has a result.
     * @param {ValidationResult} result
     */
    PageComponent.prototype.itemValidated = function (result) {
        if (result.success) {
            if (!isNullOrUndefined(this.continueEvent)) {
                this.continueEvent.emit(this.pageInfo);
            }
        }
        else {
            this.busy = false;
            this.disable = false;
            this.parentEventService.publishPageBusyStatus(false);
        }
    };
    PageComponent.prototype.resetForm = function () {
        this.pageForm.reset();
        for (var item in this.pageForm.controls) {
            if (this.pageForm.controls.hasOwnProperty(item)) {
                this.pageForm.get(item).reset();
                this.pageForm.get(item).setErrors(null);
            }
        }
    };
    PageComponent.prototype.continueClick = function () {
        if (this.busy) {
            return;
        }
        this.busy = true;
        this.continue();
    };
    PageComponent.prototype.continue = function () {
        this.disable = true;
        this.parentEventService.publishPageBusyStatus(true);
        var waitForValidation = false;
        for (var _i = 0, _a = this._pageInfo.components; _i < _a.length; _i++) {
            var ctrl = _a[_i];
            if (ctrl.dynamicControlRef.validate()) {
                waitForValidation = true;
            }
        }
        if (!waitForValidation) {
            if (!isNullOrUndefined(this.continueEvent)) {
                this.continueEvent.emit(this.pageInfo);
            }
        }
    };
    Object.defineProperty(PageComponent.prototype, "disableContinue", {
        get: function () {
            this.checkPageValidity();
            // log.info('pageValid [' + this.pageValid + '], formInvalid [' + this.pageForm.invalid + '], busy [' + this.busy + '], disable [' + this.disable + ']');
            return !this.pageValid || this.pageForm.invalid || this.busy || this.disable;
        },
        enumerable: true,
        configurable: true
    });
    PageComponent.prototype.resume = function (mustResume) {
        if (mustResume) {
            this.pageInfo = this.dspService.resumeStorage;
            this.dspService.currentData = this.dspService.resumeStorage;
        }
    };
    PageComponent.prototype.altContinue = function (eventName) {
        if (this.busy) {
            return;
        }
        if (!isNullOrUndefined(eventName) && eventName !== '') {
            this.pageInfo.continueValue = eventName;
        }
        this.continue();
    };
    PageComponent.prototype.clearFormControls = function () {
        for (var item in this.pageForm.controls) {
            if (item !== 'placeholder') {
                this.pageForm.removeControl(item);
            }
            else {
                this.pageForm.get(item).reset();
            }
        }
        this.pageForm.reset();
    };
    Object.defineProperty(PageComponent.prototype, "showTitle", {
        get: function () {
            return !isNullOrUndefined(this.pageInfo) && !isNullOrUndefined(this.pageInfo.title) && this.pageInfo.title !== '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageComponent.prototype, "pageClass", {
        get: function () {
            if (!this.showTitle) {
                return 'page no-title';
            }
            return 'page';
        },
        enumerable: true,
        configurable: true
    });
    PageComponent.prototype.setNotBusy = function () {
        this.busy = false;
        this.disable = false;
    };
    PageComponent.prototype.getBooleanValueFromForm = function (formKey) {
        return this.pageForm.get(formKey).value;
    };
    PageComponent.prototype.createCases = function () {
        var _this = this;
        this.createCaseEnabled = false;
        this.caseOptionsDisabled = true;
        if (this.getBooleanValueFromForm('createPostDocCase')) {
            log.info('creating post doc case');
            if (!isNullOrUndefined(this._pageInfo.policyId)) {
                this.dspService.createPostDocCase(this._pageInfo.policyId, this.addressNotes).subscribe(function () {
                    log.info('post doc case created');
                    _this.casesCreated = true;
                });
            }
        }
        if (this.getBooleanValueFromForm('createPostSaleCase')) {
            log.info('creating post sale case');
            if (!isNullOrUndefined(this._pageInfo.contactId)) {
                this.dspService.createPostSaleServiceCase(this._pageInfo.contactId, this.caseNotes).subscribe(function () {
                    log.info('post sale case created');
                    _this.casesCreated = true;
                });
            }
        }
    };
    PageComponent.prototype.updateCaseForWebAvsInvalid = function () {
        return __awaiter(this, void 0, void 0, function () {
            var success;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!isNullOrUndefined(this._pageInfo.spId)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.dspService.updateTheCaseForWebAvsInvalid(this._pageInfo.spId)];
                    case 1:
                        success = _a.sent();
                        if (success) {
                            log.info('Case updated to customer_responded');
                            return [2 /*return*/, true];
                        }
                        else {
                            log.info('Case not updated');
                            return [2 /*return*/, false];
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return PageComponent;
}());
export { PageComponent };
var Row = /** @class */ (function () {
    function Row() {
        this.items = [];
    }
    return Row;
}());
